<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          {{ tier_name }}
        </v-card-title>
        <v-subheader> </v-subheader>
        <v-card-subtitle class="title">
          <v-row> {{ doc_title }}</v-row>
          <v-row>
            <span>
              {{
                (doc.doc_type == 5 ? "Montant " : "Montant HT: ") +
                numberWithSpace(montant_ht.toFixed(2))
              }}
            </span>
            <v-spacer></v-spacer>
            <span v-if="doc.doc_type != 5">
              {{ "Montant TTC : " + numberWithSpace(montant_ttc.toFixed(2)) }}
            </span>
            <br />

            <v-spacer></v-spacer>
            <span v-if="item.ttc_paye">
              {{
                "Paiements : " +
                numberWithSpace(item.ttc_paye ? item.ttc_paye.toFixed(2) : "0")
              }}
            </span>
            <span v-if="item.type_paye">
              Mode de Paiement :
              {{ item.type_paye }}
            </span>
          </v-row>

          <v-subheader> </v-subheader>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <span>
              {{ date_opr }}
            </span>
          </v-row>

          <v-row v-if="item.nomvm">{{
            "N° Bon de Livraison : " + item.nomvm
          }}</v-row>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <listitems
                :list="detail_list"
                :headers="headers_list"
                :master="true"
                :key="dl"
                :add="false"
                :del="false"
                :Update="false"
                :showedit="false"
                :Total="true"
                @open="open"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container fill-height>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>
          </v-container>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click.stop="close"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import listitems from "./ListItems.vue";
import CMD from "../graphql/Commande/CMD_ID.gql";
import MVM from "../graphql/Mouvment/MVM_ID.gql";
import FACT from "../graphql/Facture/FACT_ID.gql";
import PAIEMENT from "../graphql/Paiement/PAIEMENT_ID.gql";

export default {
  components: {
    listitems,
  },
  props: {
    doc: Object,
    showform: Boolean,
    tiertype: { type: Number, default: 1 },
  },
  data() {
    return {
      progress: false,
      dl: 0,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",

      detailmvmheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
          totlib: true,
        },
        {
          text: "Depot",
          value: "depot_name",
          selected: true,
        },
        {
          text: "Produit",
          value: "product_name",
          selected: true,
        },
        {
          text: "Qte",
          value: "qte",
          selected: true,
          slot: "cur",
          align: "end",
          total: "qte",
        },
        {
          text: "prix",
          value: "pu",
          selected: true,
          slot: "cur",
          align: "end",
        },
        {
          text: "TVA",
          value: "tva_tx",
          selected: true,
          slot: "cur22",
          align: "end",
        },
        {
          text: "HT",
          value: "ht",
          selected: true,
          slot: "cur22",
          align: "end",
          total: "ht",
        },
        {
          text: "TTC",
          value: "total",
          selected: true,
          slot: "cur22",
          align: "end",
          total: "total",
        },
      ],
      detailfactheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
          width: 20,
        },
        {
          text: "Designation",
          value: "label",
          column: "article_id",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "PU HT",
          value: "pu",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Remise(%)",
          value: "remise",
          slot: "cur",
          hiden: true,
          align: "end",
          selected: true,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          selected: true,
        },
      ],
      factheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Facture No",
          value: "nofact",
          selected: true,
        },
        {
          text: "Date Facture",
          value: "fact_date",
          selected: true,
        },
        {
          text: "T.T.C",
          value: "ttc_fact",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Montant Reglé",
          value: "montant",
          slot: "cur",
          align: "end",
          selected: true,
        },
      ],
      mvmheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "BL No",
          value: "nobon",
          selected: true,
        },
        {
          text: "Date BL",
          value: "mvm_date",
          slot: "date",
          selected: true,
        },
        {
          text: "T.T.C",
          value: "ttc_bl",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Montant Reglé",
          value: "montant",
          slot: "cur",
          align: "end",
          selected: true,
        },
      ],
      detailcmdheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Produit",
          value: "label",
          selected: true,
        },
        {
          text: "Qte Cmd",
          value: "qte",
          selected: true,
          slot: "cur22",
          align: "end",
        },
        {
          text: "Qte Lanc",
          value: "qte_lanc",
          selected: true,
          slot: "cur22",
          align: "end",
        },
        {
          text: "Qte Liv",
          value: "qte_liv",
          selected: true,
          slot: "cur22",
          align: "end",
        },
      ],
      detailpayeheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "No Facture",
          value: "nofact",
          selected: true,
        },
        {
          text: "date facture",
          value: "fact_date",
          selected: true,
        },
      ],
      item: {},
    };
  },
  mounted() {
    if (this.doc.document_id) {
      let query;
      let result;
      let v;
      v = {
        id: this.doc.document_id,
      };
      switch (this.doc.doc_type) {
        case 2:
          query = CMD;
          result = "cmdfind";
          break;
        case 3:
          query = MVM;
          result = "mvmfind";
          v = {
            mvm_id: this.doc.document_id,
          };
          break;
        case 4:
          query = FACT;
          result = "factfind";
          break;

        case 5:
          query = PAIEMENT;
          result = "paiementfind";
          v = {
            paye_id: this.doc.document_id,
          };
          break;
        default:
          break;
      }
      this.progress = true;
      this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          this.item = data.data[result];
          this.progress = false;
          this.dl++;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    }
  },
  computed: {
    detail_extra: function () {
      return this.item.factdetailsextra ? this.item.factdetailsextra : [];
    },
    fact_detail() {
      let items = [];
      let k;
      if (this.item.msq_origin == 0)
        this.item.factdetails.forEach((element) => {
          k = items.findIndex(
            (elm) =>
              elm.article_id == element.article_id &&
              elm.pu == element.pu &&
              elm.remise == element.remise &&
              elm.description == element.description
          );
          if (k >= 0) {
            items[k].qte = items[k].qte + element.qte;
            items[k].ht =
              (element.pu - (element.pu * element.remise) / 100) * items[k].qte;
          } else
            items.push({
              id: element.id,
              article_id: element.article_id,
              produit_id: element.produit_id,
              code: element.code,
              label: element.product_name,
              pu: element.pu,
              remise: element.remise,
              qte: element.qte,
              mvm_id: element.mvm_id,
              ht:
                (element.pu - (element.pu * element.remise) / 100) *
                element.qte,
              tva_tx: element.tva_tx,
              comment: element.comment,
              description: element.description,
            });
        });
      this.detail_extra.forEach((element) => {
        items.push({
          id: element.id,
          code: element.code,
          label: element.label,
          produit_id: element.produit_id,
          nobon: "",
          mont_ttc: element.pu,
          pu: element.pu,
          qte: element.qte,
          remise: 0,
          ht: parseFloat((element.pu * element.qte).toFixed(2)),
          tva_tx: element.tva_tx,
          comment: element.comment,
        });
      });

      return items;
    },
    tier_name() {
      let l;
      switch (this.tiertype) {
        case 1:
          l = "Client : " + this.item.tier_name;
          break;
        case 2:
          l = "Fournisseur : " + this.item.tier_name;
          break;
        case 3:
          l = "Departement Production ";
          break;

        default:
          break;
      }
      return l;
    },
    headers_list() {
      let l;
      switch (this.doc.doc_type) {
        case 2:
          l = this.detailcmdheaders;
          break;
        case 3:
          l = this.detailmvmheaders;
          break;
        case 4:
          l = this.detailfactheaders;
          break;
        case 5:
          if (this.item.facture == "0") l = this.mvmheaders;
          else l = this.factheaders;
          break;
        default:
          break;
      }

      return l;
    },
    doc_title() {
      let t;
      switch (this.doc.doc_type) {
        case 2:
          t = "Commande N°: " + this.item.nocmd;
          break;
        case 3:
          t = "Bon d'enlevement N°: " + this.item.nobon;
          break;
        case 4:
          t = "Facture N°: " + this.item.nofact;
          break;
        case 5:
          t = "Paiement N°: " + this.item.nopaye;
          break;
        default:
          break;
      }
      return t;
    },
    date_opr() {
      let d;
      switch (this.doc.doc_type) {
        case 2:
          d = "Date commande : " + this.item.date_cmd;
          break;
        case 3:
          d = "Date enlevement : " + this.item.mvm_date;
          break;
        case 4:
          d = "Date facture : " + this.item.fact_date;
          break;
        case 5:
          d = "Date paiement : " + this.item.paye_date;
          break;
        default:
          break;
      }
      return d;
    },
    montant_ht() {
      let m = 0;
      switch (this.doc.doc_type) {
        case 2:
          m = this.item.ht ? this.item.ht : 0;
          break;
        case 3:
          m = this.item.mont_ht ? this.item.mont_ht : 0;
          break;
        case 4:
          m = this.item.mont_ht ? this.item.mont_ht : 0;
          break;
        case 5:
          m = this.item.montant ? this.item.montant : 0;
          break;
        default:
          break;
      }
      return m;
    },
    montant_ttc() {
      let m = 0;
      switch (this.doc.doc_type) {
        case 2:
          m = this.item.ttc ? this.item.ttc : 0;
          break;
        case 3:
          m = this.item.mont_ttc ? this.item.mont_ttc : 0;
          break;
        case 4:
          m = this.item.mont_ttc ? this.item.mont_ttc : 0;
          break;
        case 5:
          m = this.item.montant ? this.item.montant : 0;
          break;
        default:
          break;
      }
      return m;
    },
    detail_list() {
      let dl;
      switch (this.doc.doc_type) {
        case 2:
          dl = this.item ? this.item.cmddetails : [];
          break;
        case 3:
          dl = this.item ? this.item.mvmdetails : [];
          break;
        case 4:
          dl = this.item ? this.fact_detail : [];
          break;
        case 5:
          dl = this.item ? this.item.payedetails : [];
          break;
        default:
          break;
      }
      return dl;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    open(item) {
      this.$emit("open", item);
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
